import { autoinject, bindable, observable, Scope, computedFrom } from "aurelia-framework";
import { RestService, GlobalizationService, ScopeContainer, LocalizationService } from '../../../framework/base/export';
import { IdxPopoverComponent } from '../../interfaces/export';
import { ModelUtilsService, FormBase } from '../../../framework/forms/export';

@autoinject
export class MhdChargeMengeErf {
  constructor(
    private restService: RestService,
    private globalizationService: GlobalizationService,
    private localizationService: LocalizationService,
    private modelUtilsService: ModelUtilsService
  ) { }

  mhdChargeList: any[] = [];
  mhdChargeDeleteList: number[] = [];

  @bindable @observable mainModel: any;
  @bindable @observable idArtikel: number;
  @bindable @observable isArtikelStuecklistenkomponente: boolean;
  @bindable @observable isMhdChargeLagerDeaktiviert: boolean;
  @bindable isReadOnly: boolean;
  @bindable isRequired: boolean;
  @bindable mengeProperty: string;

  mhdChargeContainer: any;
  hasMhdCharge: boolean;
  artikel: any;
  itemRowClass: string;
  form: FormBase;

  scope: Scope;
  scopeContainer: ScopeContainer;

  mengeNumberBoxOptions: DevExpress.ui.dxNumberBoxOptions = {
    format: this.globalizationService.getNumberFormat("n2"),
    min: 0,
    onValueChangedByUser: (e) => {
      this.fireMengeEditorValueChanged(e.value);
    },
    bindingOptions: {
      readOnly: "isReadOnlyMenge"
    }
  };
  openPopupButtonOptions: DevExpress.ui.dxButtonOptions = {
    icon: "fa fa-fw fa-pencil",
    onClick: (e) => {
      this.mhdChargeDeleteList = [];
      this.mhdChargeList = this.mhdChargeContainer.MHDChargeEintraege.map(c => {
        return {
          Ref: c,
          MHD: c.MHD,
          Charge: c.Charge,
          MengeVerpEH: c.MengeVerpEH
        }
      });

      if (!this.isReadOnlyChecked && this.mhdChargeList.length == 0) {
        this.mhdChargeList.push(...[{}, {}, {}]);
      }

      this.popover.instance.show(e.event.currentTarget);
    }
  }
  mhdDateBoxOptions: DevExpress.ui.dxDateBoxOptions = {
    displayFormat: this.globalizationService.getFormatterParser("d"),
    min: new Date(1800, 0, 1),
    max: new Date(2099, 11, 31),
    width: "100%",
    bindingOptions: {
      value: "item.MHD",
      readOnly: "isReadOnlyChecked"
    }
  }
  chargeTextBoxOptions: DevExpress.ui.dxTextBoxOptions = {
    maxLength: 50,
    width: "100%",
    bindingOptions: {
      value: "item.Charge",
      readOnly: "isReadOnlyChecked"
    }
  }
  mhdChargeMengeNumberBoxOptions: DevExpress.ui.dxNumberBoxOptions = {
    format: this.globalizationService.getNumberFormat("n2"),
    min: 0,
    width: "100%",
    bindingOptions: {
      value: "item.MengeVerpEH",
      readOnly: "isReadOnlyChecked"
    }
  }
  deleteButtonOptions: DevExpress.ui.dxButtonOptions = {
    icon: "fa fa-fw fa-times",
    tabIndex: -1,
    width: "100%",
    onClick: (e) => {
      const item = e.model.bindingContext.item;
      const index = this.mhdChargeList.indexOf(item);
      if (index < 0) {
        return;
      }

      this.mhdChargeList.splice(index, 1);

      if (item.Ref) {
        this.mhdChargeDeleteList.push(item.Ref);
      }
    },
    bindingOptions: {
      disabled: "isReadOnlyChecked"
    }
  }
  addButtonOptions: DevExpress.ui.dxButtonOptions = {
    icon: "fa fa-fw fa-plus",
    onClick: () => {
      this.mhdChargeList.push({});
    },
    bindingOptions: {
      disabled: "isReadOnlyChecked"
    }
  }
  submitButtonOptions: DevExpress.ui.dxButtonOptions = {
    text: this.localizationService.translateOnce("mhd_charge_menge.uebernehmen"),
    onClick: () => {
      let mengeVerpEH = 0;

      this.mhdChargeList.forEach(c => {
        let remove = false;

        if (this.artikel.SupportsMHD && !c.MHD) {
          remove = true;
        } else if (this.artikel.SupportsCharge && !c.Charge) {
          remove = true;
        } else if (!c.MengeVerpEH) {
          remove = true;
        }

        if (remove) {
          if (c.Ref) {
            this.mhdChargeDeleteList.push(c.Ref);
          }
        } else {
          let ref = c.Ref;
          if (!ref) {
            ref = {};
            this.mhdChargeContainer.MHDChargeEintraege.push(ref)
          };

          ref.MHD = c.MHD;
          ref.Charge = c.Charge;
          ref.MengeVerpEH = c.MengeVerpEH;

          mengeVerpEH += c.MengeVerpEH;
        }
      });

      this.mhdChargeDeleteList.forEach(c => {
        const index = this.mhdChargeContainer.MHDChargeEintraege.indexOf(c);
        if (index < 0) {
          return;
        }

        this.mhdChargeContainer.MHDChargeEintraege.splice(index, 1);
      });

      this.mainModel.MHDChargeContainer = this.mhdChargeContainer;

      this.mainModel[this.mengeProperty] = mengeVerpEH;
      this.modelUtilsService.setDirty(this.mainModel);
      this.fireMengeEditorValueChanged(mengeVerpEH);

      this.popover.instance.hide();
    },
    bindingOptions: {
      disabled: "isReadOnlyChecked"
    }
  }
  cancelButtonOptions: DevExpress.ui.dxButtonOptions = {
    text: this.localizationService.translateOnce("mhd_charge_menge.abbrechen"),
    onClick: () => {
      this.popover.instance.hide();
    }
  }
  popover: IdxPopoverComponent;
  popoverOptions: DevExpress.ui.dxPopoverOptions = {
    contentTemplate: "contentTemplate",
    width: "500px"
  }

  @computedFrom("isReadOnly", "isReadOnlyInternal")
  get isReadOnlyChecked() {
    if (this.isReadOnly) {
      return true;
    }
    if (this.isReadOnlyInternal) {
      return true;
    }

    return false;
  }
  @computedFrom("isReadOnlyInternal", "hasMhdCharge")
  get isReadOnlyMenge() {
    if (this.isReadOnlyInternal) {
      return true;
    }
    if (this.hasMhdCharge) {
      return true;
    }

    return false;
  }
  @computedFrom("idArtikel", "mainModel", "mainModel.CanSave", "isArtikelStuecklistenkomponente")
  get isReadOnlyInternal() {
    if (!this.idArtikel) {
      return true;
    }
    if (!this.mainModel) {
      return true;
    }
    if (this.mainModel.CanSave != void (0) && !this.mainModel.CanSave) {
      return true;
    }

    return false;
  }

  bind(bindingContext) {
    this.form = bindingContext;

    this.scope = {
      bindingContext: this,
      overrideContext: null
    };
    this.scopeContainer = new ScopeContainer(this.scope);

    if (!this.mengeProperty) {
      throw new Error("propertyMenge nicht definiert");
    }
    if (this.isRequired) {
      this.mengeNumberBoxOptions["validators"] = [{
        type: "required",
        message: "Pflichtfeld",
      }];
    }

    this.mengeNumberBoxOptions.bindingOptions.value = `mainModel.${this.mengeProperty}`;

    this.loadArtikelData();
    this.loadMHDChargeContainer();
  }
  unbind() {
    this.scopeContainer.disposeAll();
    this.scopeContainer = null;
  }

  mainModelChanged() {
    this.loadMHDChargeContainer();
  }
  idArtikelChanged() {
    this.loadArtikelData();
  }
  isMhdChargeLagerDeaktiviertChanged() {
    this.loadMHDChargeContainer();
    this.checkIsMhdChargeLagerDeaktiviert();
  }

  private async loadMHDChargeContainer() {
    this.mhdChargeContainer = null;

    if (!this.mainModel) {
      return;
    }
    
    if (this.mainModel.MHDChargeContainer && !this.isMhdChargeLagerDeaktiviert) {
      this.mhdChargeContainer = this.mainModel.MHDChargeContainer;
    } else if (this.mainModel.IdMHDChargeContainer) {
      this.mhdChargeContainer = await this.restService.get({
        url: this.restService.getWebApiUrl("ERP/Lager/MHDChargeContainer/".concat(this.mainModel.IdMHDChargeContainer)),
        getOptions: {
          expand: {
            "MHDChargeEintraege": null
          }
        }
      });
    } else {
      this.mhdChargeContainer = {
        MHDChargeEintraege: []
      };
    }
  }
  private async loadArtikelData() {
    if (!this.idArtikel) {
      return;
    }

    this.artikel = await this.restService.get({
      url: this.restService.getWebApiUrl("ERP/Stammdaten/Artikel/".concat(this.idArtikel.toString())),
      getOptions: {
        columns: ["Id", "SupportsMHD", "SupportsCharge"]
      }
    });

  this.checkIsMhdChargeLagerDeaktiviert();
  }
  private checkIsMhdChargeLagerDeaktiviert(){
    if (!this.idArtikel || this.artikel == null) {
      return;
    }
    
    this.hasMhdCharge = !this.isMhdChargeLagerDeaktiviert && (this.artikel.SupportsMHD || this.artikel.SupportsCharge);

    this.itemRowClass = !this.isMhdChargeLagerDeaktiviert && this.artikel.SupportsMHD && this.artikel.SupportsCharge
      ? "mhd-charge-menge-erf-item-row"
      : "mhd-menge-erf-item-row";
  }
  private fireMengeEditorValueChanged(mengeVerpEH: number) {
    const model = this.form.models
      .getModels()
      .find(c => this.form.models.data[c.id] == this.mainModel);

    if (!model) {
      return;
    }

    this.form.onEditorValueChanged.fire({
      binding: {
        bindTo: this.mengeProperty,
        bindToFQ: this.mengeProperty,
        dataContext: model.id
      },
      value: mengeVerpEH
    });
  }
}
