import { autoinject, transient } from "aurelia-framework";
import { OnLieferscheinListAbschliessenEvent } from "../../../../framework-data/events";
import { LocalizationService, ScopeContainer, WebEventService } from "../../../../framework/base/export";
import { AsyncService } from "../../../../framework/base/services/async-service";
import { FormBase, ICommandData } from "../../../../framework/forms/export";
import { IdxDataGridComponent } from "../../../interfaces/export";
import { ValidationExService } from "../../../services/export";

@autoinject
@transient()
export class LieferscheinListUtils {
  private NOTIFY_TIMEOUT: number = 3000;

  constructor(
    private _webEventService: WebEventService,
    private _asyncService: AsyncService,
    private _localizationService: LocalizationService,
    private _validationExService: ValidationExService) { }

  form: FormBase;
  scopeContainer: ScopeContainer;

  doAbschliessenCommand: ICommandData = {
    id: "doAbschliessen",
    idCategory: "lieferschein",
    title: "lieferschein.abschliessen",
    icon: "check",
    sortIndex: 999,
    execute: async () => {
      const grid: IdxDataGridComponent = this.form["r_lieferscheinGrid"];
      await this._asyncService.convertToPromise(grid.instance.selectAll());
      const lieferscheinGridRowData = await this._asyncService.convertToPromise(grid.instance.getSelectedRowsData());

      if (lieferscheinGridRowData.length == 0) {
        return;
      }
      
      grid.instance.clearSelection();

      const title = this._localizationService.translateOnce("base.question");
      const question = this._localizationService.translateOnce("lieferschein.sure_abschliessen");

      const isConfirmed = await this._asyncService.convertToPromise(DevExpress.ui.dialog.confirm(question, title));
      if (!isConfirmed) {
        return;
      }

      const hasErledigteLieferscheine = lieferscheinGridRowData
        .some((c) => c.Status.Typ != 0);

      if (hasErledigteLieferscheine) {
        DevExpress.ui.notify(
          this._localizationService.translateOnce("lieferschein.fehler_abschliessen_status"),
          "error",
          this.NOTIFY_TIMEOUT);
        return;
      }

      lieferscheinGridRowData.forEach(async (lieferschein) => {
        const validationResult = await this._validationExService.executeServerValidation(
          "TIP.ERP.Business.Entitaeten.Verkauf.Lieferschein",
          lieferschein.Id,
          "ABSCHLIESSEN"
        );

        if (!validationResult) {
          return;
        }
      });

      const idLieferscheinList = lieferscheinGridRowData.map((c) => c.Id);

      await this._webEventService.execute(new OnLieferscheinListAbschliessenEvent({
        IdLieferscheinList: idLieferscheinList
      }), true);

      return this.form.models.reloadAll();
    }
  };

  bind(form: FormBase, namespace: string) {
    this.form = form;

    this.scopeContainer = new ScopeContainer({
      bindingContext: this,
      overrideContext: null
    });
  }
}
