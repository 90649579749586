import * as fwx from "../../../../framework/forms/form-export";

@fwx.autoinject
export class FibuOssEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport) {
        super(element, formBaseImport);
        this.id = "fibu-oss-edit";
        this.title = "fibu-oss-edit.fibu-oss-edit_caption";
        this.addModel({
            "id": "$m_FibuOSS",
            "webApiAction": "ERP/Fibu/FibuOSS",
            "webApiExpand": {
                'Land': null
            },
            "optimisticLockingEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "filters": []
        });
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "ossTyp",
            "filters": [],
            "caption": "fibu-oss-edit.r_fibuosstyp_caption",
            "binding": {
                "dataContext": "$m_FibuOSS",
                "bindTo": "OSSTyp",
                "bindToFQ": "models.data.$m_FibuOSS.OSSTyp"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_fibuOssTyp",
            "options": {
                "optionsName": "r_fibuOssTypOptions",
                "optionsNameFQ": "r_fibuOssTypOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "fibuTyp",
            "filters": [],
            "caption": "fibu-oss-edit.r_fibuossfibutyp_caption",
            "binding": {
                "dataContext": "$m_FibuOSS",
                "bindTo": "FibuTyp",
                "bindToFQ": "models.data.$m_FibuOSS.FibuTyp"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_fibuOSSFibuTyp",
            "options": {
                "optionsName": "r_fibuOSSFibuTypOptions",
                "optionsNameFQ": "r_fibuOSSFibuTypOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "land",
            "filters": [],
            "caption": "fibu-oss-edit.r_fibuossland_caption",
            "binding": {
                "dataContext": "$m_FibuOSS",
                "bindTo": "IdLand",
                "bindToFQ": "models.data.$m_FibuOSS.IdLand"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_fibuOSSLand",
            "options": {
                "optionsName": "r_fibuOSSLandOptions",
                "optionsNameFQ": "r_fibuOSSLandOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 10,
            "caption": "fibu-oss-edit.r_fibuosscode_caption",
            "binding": {
                "dataContext": "$m_FibuOSS",
                "bindTo": "Code",
                "bindToFQ": "models.data.$m_FibuOSS.Code"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }, {
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 10
                    }]
                }
            }],
            "id": "r_fibuOSSCode",
            "options": {
                "optionsName": "r_fibuOSSCodeOptions",
                "optionsNameFQ": "r_fibuOSSCodeOptions"
            }
        });
        super.onConstructionFinished();
    }
}