import { autoinject, transient } from "aurelia-framework";
import { OnAuftragspositionArtikelErsetzen, OnAuftragspositionArtikelErsetzenEvent, OnAuftragspositionRecalcLieferdatumEvent, OnAuftragspositionVariableStuecklisteAuswahl, OnAuftragspositionVariableStuecklisteAuswahlEvent, OnAuftragspositionVariableStuecklistenkomponentenErstellen, OnAuftragspositionVariableStuecklistenkomponentenErstellenEvent, OnAuftragspositionVariablerSetartikelManuellerPreisEntfernenEvent, OnAuftragspositionVariablerSetartikelPreisAendernEvent, OnAuftragspositionVariablerSetartikelRabattEntfernenEvent, OnEvalVerkaufArtikeldaten, OnEvalVerkaufArtikeldatenEvent } from "../../../../framework-data/events";
import { LocalizationService, WebEventService } from "../../../../framework/base/export";
import { AsyncService } from "../../../../framework/base/services/async-service";
import { FormBase, ICommandData, ModelUtilsService, PopupInfoService } from "../../../../framework/forms/export";
import { CalcLiefertermin } from '../../../elements/calc-liefertermin/calc-liefertermin';
import { VariablerSetartikelKomponentenAuswahl } from "../../../elements/variabler-setartikel-komponenten-auswahl/variabler-setartikel-komponenten-auswahl";
import { IVariablerSetartikelPreisAendernResult, VariablerSetartikelPreisAendern } from "../../../elements/variabler-setartikel-preis-aendern/variabler-setartikel-preis-aendern";
import { IKalkulationRefreshOptions } from "../../../interfaces/kalkulation-refresh-options";
import { BestellungService } from "../../../services/bestellung-service";
import { ArtikelService, AuftragService, DynFelderDataService, DynFelderService, StartupService } from '../../../services/export';
import { KalkulationRefreshService } from "../../../services/kalkulation-refresh-service";
import { DxCustomizeService } from './../../../services/dx-customize-service';
import { DynFeldLogikTyp } from "../../../enumerations/dyn-feld-logik-typ";

@autoinject
@transient()
export class AuftragspositionEditUtils {
  private NOTIFY_TIMEOUT: number = 3000;

  constructor(
    public startupService: StartupService,
    public dynFelderDataService: DynFelderDataService,
    private _auftragService: AuftragService,
    private _asyncService: AsyncService,
    private _bestellungService: BestellungService,
    private _kalkulationRefreshService: KalkulationRefreshService,
    private _modelUtilsService: ModelUtilsService,
    private _artikelService: ArtikelService,
    private _dynFelderService: DynFelderService,
    private _webEventService: WebEventService,
    private _popupInfoService: PopupInfoService,
    private _localizationService: LocalizationService,
    private _dxCustomizeService: DxCustomizeService) { }

  form: FormBase;
  refreshOptions: IKalkulationRefreshOptions;
  idArtikelErsetzen: number;
  showVariablerSetartikelCheckbox: boolean;
  variablerSetartikelKomponentenAuswahlList: any[];
  onReloadStuecklistemkomponentenlist: boolean;
  mengeVerpEH: number;
  hasIntercompanyBestellung: boolean;

  showArtikelErsetzenCommand: ICommandData = {
    id: "artikelErsetzenCommand",
    idCategory: "auftragsposition",
    icon: "exchange",
    title: "auftrag.artikel_ersetzen",
    isEnabledExpression: `!!models.data.$m_Auftragsposition
      && !!models.data.$m_Auftragsposition.CanSave 
      && !!models.data.$m_Auftragsposition.Id
      && !models.data.$m_Auftragsposition.MengeLieferscheinVerpEH
      && !models.data.$m_Auftragsposition.IsPositionVariableStuecklistenkomponente
      && !models.data.$m_Auftragsposition.MengeGeliefertVerpEH
      && !models.data.$m_Auftrag.IsIcAuftrag`,
    sortIndex: 990,
    execute: async () => {
      const saveResult = await this.form.saveIfDirty();
      if (!saveResult.isValid) {
        return;
      }

      this.idArtikelErsetzen = null;

      await this.form["r_artikelErsetzenPopup"].instance.show();

      const artikel = this.form["r_artikelErsetzenElement"];
      artikel.artikelNummer.instance.focus();
    }
  }
  doArtikelErsetzenCommand: ICommandData = {
    id: "doArtikelErsetzenCommand",
    title: "auftrag.artikel_ersetzen",
    icon: "exchange",
    execute: async () => {
      const saveResult = await this.form.saveIfDirty();
      if (!saveResult.isValid) {
        return;
      }

      const webEvent = new OnAuftragspositionArtikelErsetzenEvent({
        IdAuftragsposition: this.form.models.data.$m_Auftragsposition.Id,
        IdArtikel: this.idArtikelErsetzen
      });

      if (!this.idArtikelErsetzen) {
        DevExpress.ui.notify(this._localizationService.translateOnce("auftrag.kein_artikel_ausgewaehlt"), "ERROR", 3000);
        return;
      }

      const webEventResult: OnAuftragspositionArtikelErsetzen = await this._webEventService.execute(webEvent, true);
      DevExpress.ui.notify(this._localizationService.translateOnce("auftrag.artikel_erfolgreich_ersetzt"), "SUCCESS", 3000);
      this._popupInfoService.closeAllPopups();
    }
  }
  calcLieferdatumCommand: ICommandData = {
    id: "calcLieferdatumCommand",
    icon: "calendar",
    idCategory: "artikel_bestellvorschlag",
    title: "auftrag.calc-lieferdatum",
    sortIndex: 100,
    isEnabledExpression: "models.data.$m_Auftragsposition.IdArtikel > 0 && models.data.$m_Auftragsposition.CanSave",
    isVisible: this.startupService.startupInfo.Mandant.IsBerechnungLieferdatumAktiv,
    execute: async (e) => {
      const calcLiefertermin: CalcLiefertermin = this.form["r_calcLiefertermin"];
      calcLiefertermin.show(
        this.form.models.data.$m_Auftragsposition.IdArtikel,
        this.form.models.data.$m_Auftragsposition.IdLieferlager || this.form.models.data.$m_Auftrag.IdLieferlager,
        this.form.models.data.$m_Auftragsposition.MengeVerpEH,
        this.form.models.data.$m_Auftragsposition.IdEinheit);
    }
  }
  recalcLieferdatumCommand: ICommandData = {
    id: "recalcLieferdatumCommand",
    icon: "calculator",
    idCategory: "artikel_bestellvorschlag",
    title: "auftrag.recalc-lieferdatum",
    sortIndex: 100,
    isEnabledExpression: "models.data.$m_Auftragsposition.Id > 0 && models.data.$m_Auftragsposition.CanSave",
    isVisible: this.startupService.startupInfo.Mandant.IsBerechnungLieferdatumAktiv,
    execute: async (e) => {
      const saveResult = await this.form.saveIfDirty();
      if (!saveResult.isValid) {
        return;
      }

      await this._webEventService.execute(new OnAuftragspositionRecalcLieferdatumEvent({
        IdAuftragsposition: this.form.models.data.$m_Auftragsposition.Id
      }), true);

      this.form.models.reloadAll();

      DevExpress.ui.notify(
        this._localizationService.translateOnce("auftrag.lieferdatum-neu-berechnet"),
        "success",
        3000
      );
    }
  }
  showVariablerSetartikelKomponentenAuswahlCommand: ICommandData = {
    id: "variablerSetartikelKomponentenAuswahlCommand",
    idCategory: "auftragsposition",
    icon: "list",
    title: "auftrag.stueckliste_auswaehlen",
    isVisibleExpression: "functions.$f_AuftragspositionEditUtils.startupService.startupInfo.Lizenz.HasVariablerSetartikel && !!models.data.$m_Auftragsposition && models.data.$m_Auftragsposition.Id > 0 && models.data.$m_Auftragsposition.IsVariablerSetartikel && models.data.$m_Auftragsposition.CanSave",
    sortIndex: 991,
    execute: async () => {
      this.showVariablerSetartikelStuecklistenKomponentenAuswahl();
    }
  }
  showVariablerSetartikelPreisAendernCommand: ICommandData = {
    id: "variablerSetartikelPreisAendernCommand",
    idCategory: "auftragsposition",
    icon: "money",
    title: "auftrag.preis_aendern",
    isVisibleExpression: "functions.$f_AuftragspositionEditUtils.startupService.startupInfo.Lizenz.HasVariablerSetartikel && !!models.data.$m_Auftragsposition && models.data.$m_Auftragsposition.Id > 0 && models.data.$m_Auftragsposition.IsVariablerSetartikel && models.data.$m_Auftragsposition.CanSave",
    sortIndex: 991,
    execute: async () => {
      this.showVariablerSetartikelPreisAendernAuswahl();
    }
  }

  onMengeReserviertCellPrepared(e): void {
    if (e.rowType != "data" || this.form.models.data.$m_Auftragsposition.IsAuftragBestaetigt == false || e.data.Status.Typ === 1) {
      return;
    }

    const rowData = e.data;
    const cell: HTMLTableCellElement = e.cellElement;

    cell.style.color = "white";
    cell.style.backgroundColor = rowData.MengeReserviertVerpEH < rowData.MengeOffenVerpEH
      ? "#C1392D"
      : (rowData.HasLagerfuehrung ? "#2DCC70" : "#566473");
  }

  bind(form: FormBase, namespace: string) {
    this.form = form;

    this.dynFelderDataService.register(this.form, {
      idMainModel: "$m_Auftragsposition",
      idVorgEntitaetPropertyName: "IdArtikel"
    });

    this.refreshOptions = {
      form: form,
      modelName: "$m_Auftragsposition",
      kalkulationRef: "r_kalkulation",
      einheitPropertyName: "IdEinheit",
      mengeVerpEHPropertyName: "MengeVerpEH"
    }

    this._kalkulationRefreshService.registerRefresh(this.refreshOptions);

    form.editPopups.onEditPopupHidden.register((r) => {
      if (r.hasDataReloaded) {
        return Promise.resolve();
      }

      if (r.editPopup.id == "auftragspositionEditPopup") {
        this.form.models.reloadAll();
      }

      return Promise.resolve();
    });

    this.form.models.onLoaded.register(async (e) => {
      if (e == void 0) {
        return;
      }
      else if (e.model.id == "$m_Auftrag") {
        if (this.form.models.data.$m_Auftragsposition && this.form.models.data.$m_Auftrag) {
          this.mengeVerpEH = this.form.models.data.$m_Auftragsposition.MengeVerpEH;

          this.hasIntercompanyBestellungBereitsErstellt();
          this.refreshAuftragsgruppeButton();
        }
      }
      else if (e.model.id == "$m_Auftragsposition") {
        this.showVariablerSetartikelCheckbox = this.form.models.data.$m_Auftragsposition
          && this.form.models.data.$m_Auftragsposition.Artikel != null
          && this.form.models.data.$m_Auftragsposition.Artikel.BezugTyp == 3
          ? true
          : false;

        this.refreshAuftragsgruppeButton();
      }
    });

    this.form.onSaving.register(async (r) => {
      if (r.form.models.hasChangedData()) {
        let result = await this.validateVariablerSetartikel();

        if (!result) {
          throw new Error();
        }

        result = await this.validateIntercompany();

        if (!result) {
          throw new Error();
        }
      }

      if (!this.startupService.startupInfo.Lizenz.HasVariablerSetartikel) {
        return;
      }

      if (!this.form.models.data.$m_Auftragsposition.IsVariablerSetartikel) {
        return;
      }

      if (r.form.models.data.$m_Auftragsposition.Id > 0) {
        return;
      }

      if (this.variablerSetartikelKomponentenAuswahlList != null) {
        return;
      }

      this.showVariablerSetartikelStuecklistenKomponentenAuswahl();

      return Promise.reject();
    });

    this.form.onSaved.register(async (r) => {
      if (!this.startupService.startupInfo.Lizenz.HasVariablerSetartikel) {
        return;
      }

      if (this.variablerSetartikelKomponentenAuswahlList == null && this.form.models.data.$m_Auftragsposition.IsSetartikel) {
        if (this.onReloadStuecklistemkomponentenlist) {
          this.onReloadStuecklistemkomponentenlist = false;
          this.form.models.reloadAll();
        }
        return;
      }

      if (r.form.models.data.$m_Auftragsposition.Id > 0 && !this.form.models.data.$m_Auftragsposition.IsVariablerSetartikel) {
        return;
      }

      const webEvent = new OnAuftragspositionVariableStuecklistenkomponentenErstellenEvent({
        IdAuftragsposition: r.form.models.data.$m_Auftragsposition.Id,
        StuecklistenkomponentenMengeList: this.variablerSetartikelKomponentenAuswahlList
      });

      const result: OnAuftragspositionVariableStuecklistenkomponentenErstellen = await this._webEventService.execute(webEvent);

      this.form.models.reloadAll();
      this.variablerSetartikelKomponentenAuswahlList = null;
    });
  }

  async idArtikelChanged(e) {
    this._kalkulationRefreshService.onIdArtikelChanged(this.refreshOptions);

    if (!this.form.models.data.$m_Auftragsposition.Id) {
      await this.dynFelderDataService.loadVorbelegungen();
      this._modelUtilsService.setDirty(this.form.models.data.$m_Auftragsposition);
    }

    this.doEvalVerkaufArtikeldaten(e.detail.value);

    if (!this.startupService.startupInfo.Lizenz.HasVariablerSetartikel) {
      return;
    }

    if (e.detail.value != null) {
      const artikel = await this._artikelService.getArtikelById(e.detail.value);

      this.form.models.data.$m_Auftragsposition.IsVariablerSetartikel = false;
      this.showVariablerSetartikelCheckbox = false;
      this.variablerSetartikelKomponentenAuswahlList = null;

      if (artikel.BezugTyp != 3) {
        return;
      }

      const dynFeldEintrag = await this._dynFelderService.loadDynFeldEintragByLogikttyp(artikel.IdDynamischesFeldContainer, DynFeldLogikTyp.VariablerSetartikel);
      this.showVariablerSetartikelCheckbox = true;

      if (dynFeldEintrag != null && dynFeldEintrag[0].WertNummer == 1) {
        this.form.models.data.$m_Auftragsposition.IsVariablerSetartikel = true;
      }
    }
  }

  copyAuftragsposition($delegate: { (customData: any): Promise<any> }) {
    $delegate({ "PosNrUeberschreiben": true })
  }

  private async doEvalVerkaufArtikeldaten(idArtikel) {
    if (!idArtikel) {
      return;
    }

    const webEvent = new OnEvalVerkaufArtikeldatenEvent({
      IdArtikel: idArtikel,
      IdKunde: this.form.models.data.$m_Auftrag.IdKunde
    });

    const result: OnEvalVerkaufArtikeldaten = await this._webEventService.execute(webEvent);
    if (result == void 0) {
      return;
    }

    this.form.models.data.$m_Auftragsposition.IdEinheit = result.IdArtikelEinheit;
    this.form.models.data.$m_Auftragsposition.KundeArtikelNr = result.KundeArtikelNr;
    this.form.models.data.$m_Auftragsposition.KundeArtikelbezeichnung = result.KundeArtikelbezeichnung;
  }

  onReloadStuecklistenkomponentenChanged(e) {
    if (!this.form.models.data.$m_Auftragsposition) {
      return;
    }

    if (this.form.models.data.$m_Auftragsposition.IsSetartikel && e.previousValue != null) {
      this.onReloadStuecklistemkomponentenlist = true;
    }
  }


  onAuftragsgruppeChanged() {
    this.refreshAuftragsgruppeButton();
  }

  private refreshAuftragsgruppeButton() {
    const data = this.form.models.data.$m_Auftragsposition;
    const dataAuftrag = this.form.models.data.$m_Auftrag;

    if (!data || !dataAuftrag) {
      return;
    }
    const editor = this.form["r_auftragsgruppe"].instance;
    const buttons: DevExpress.ui.dxTextEditorButton[] = [];

    if (!data.IdAuftragsgruppe && data.CanSave && !dataAuftrag.IsIcAuftrag) {
      buttons.push({
        location: "after",
        name: "ap",
        options: {
          disabled: false,
          icon: "fa fa-plus",
          stylingMode: "text",
          tabIndex: -1,
          onClick: async () => {
            this.form.models.data.$m_AuftragsgruppeEdit = {
              Id: 0
            };

            this.form.editPopups.showEx({
              idEditPopup: "auftragsgruppeEditPopup",
              modelLoaded: (form, ev) => {
                if (!form.models.data.$m_Auftragsgruppe) {
                  return;
                }
                form.models.data.$m_Auftragsgruppe.IdAuftrag = this.form.models.data.$m_Auftragsposition.IdAuftrag;
              },
              closeCallback: async (form) => {
                this.form["r_auftragsgruppe"].instance.getDataSource().reload();

                const model = form.models.modelWithKeyId;
                const data = form.models.data[model.id];
                this.form.models.data.$m_Auftragsposition.IdAuftragsgruppe = data.Id;

                this.form.onEditorValueChanged.fire({
                  binding: {
                    dataContext: "$m_Auftragsposition",
                    bindTo: "IdAuftragsgruppe"
                  },
                  value: data.Id
                });
              }
            });
          }
        }
      });
    }

    buttons.push({
      location: "after",
      name: "clear"
    }, {
      location: "after",
      name: "dropDown"
    });

    this._dxCustomizeService.updateEditorButtons(editor, buttons);
  }

  private async hasIntercompanyBestellungBereitsErstellt() {
    if (this.startupService.startupInfo.Lizenz.HasIntercompany && this.form.models.data.$m_Auftrag && this.form.models.data.$m_Auftrag.IsIntercompany) {
      const bestellungList = await this._bestellungService.getBestellungListByIdAuftrag(this.form.models.data.$m_Auftrag.Id);

      this.hasIntercompanyBestellung = bestellungList.length > 0
        ? true
        : false;
    } else {
      this.hasIntercompanyBestellung = false;
    }
  }

  private async validateVariablerSetartikel() {
    if (!this.form.models.data.$m_Auftragsposition.IsVariablerSetartikel)
      return true;

    if (this.form.models.data.$m_Auftrag.IsIntercompany) {
      DevExpress.ui.notify(
        this._localizationService.translateOnce("auftrag.fehler_variabler_setartikel_intercompany"),
        "error",
        this.NOTIFY_TIMEOUT)
      return false;
    } else if (this.form.models.data.$m_Auftrag.IsStreckengeschaeft) {
      DevExpress.ui.notify(
        this._localizationService.translateOnce("auftrag.fehler_variabler_setartikel_streckengeschaeft"),
        "error",
        this.NOTIFY_TIMEOUT);

      return false;
    }
    return true;
  }

  private async validateIntercompany() {
    const checkIntercompany = this.startupService.startupInfo.Lizenz.HasIntercompany
      && this.form.models.data.$m_Auftrag.IsIntercompany
      && this.hasIntercompanyBestellung


    if (!checkIntercompany) {
      return true;
    }

    if (this.mengeVerpEH < this.form.models.data.$m_Auftragsposition.MengeVerpEH) {
      const icAuftragsposition = await this.getIcAuftragsposition();

      if (icAuftragsposition != null) {
        let doExecute = await this.validateIntercompanyLieferschein(icAuftragsposition);

        if (doExecute) {
          doExecute = await this.validateIntercompanyBestellung(icAuftragsposition);

          if (!doExecute) {
            return false;
          }
          return true;
        }
      }
    }

    return true;
  }

  private async validateIntercompanyLieferschein(icAuftragsposition: any) {
    if (icAuftragsposition.MengeLieferscheinVerpEH > 0) {
      const doExecute = await this._asyncService.convertToPromise(DevExpress.ui.dialog.confirm(
        this._localizationService.translateOnce("bestellung.sure_intercompany_menge_lieferschein"),
        this._localizationService.translateOnce("base.question")));

      if (!doExecute) {
        return false;
      }
      return true;
    }
    return true;
  }

  private async validateIntercompanyBestellung(icAuftragsposition: any) {
    const mengeBestellungIcAuftragsposition = await this._bestellungService.getMengeVerpEHByIdAuftragsposition(icAuftragsposition.Id);

    if (mengeBestellungIcAuftragsposition > 0) {
      const doExecute = await this._asyncService.convertToPromise(DevExpress.ui.dialog.confirm(
        this._localizationService.translateOnce("bestellung.sure_intercompany_menge_bestellung"),
        this._localizationService.translateOnce("base.question")));

      if (!doExecute) {
        return false;
      }
      return true;
    }
    return true;
  }

  private async getIcAuftragsposition() {
    const idBestellungsposition = await this._bestellungService.getIdBestellungspositionByIdAuftragsposition(this.form.models.data.$m_Auftragsposition.Id);

    if (idBestellungsposition == null) {
      return null;
    }

    return await this._auftragService.getAuftragspositionByIdIcBestellungsposition(idBestellungsposition);
  }

  private async showVariablerSetartikelStuecklistenKomponentenAuswahl() {
    const webEvent = new OnAuftragspositionVariableStuecklisteAuswahlEvent({
      IdArtikel: this.form.models.data.$m_Auftragsposition.IdArtikel,
      IdAuftragsposition: this.form.models.data.$m_Auftragsposition.Id
    });

    const result: OnAuftragspositionVariableStuecklisteAuswahl = await this._webEventService.execute(webEvent);

    if (result == void 0) {
      return;
    }

    if (result.StuecklistenkomponentenAuswahlList.length == 0) {
      return;
    }

    const variablerSetartikelAuswahlPopup: VariablerSetartikelKomponentenAuswahl = this.form["r_variablerSetartikelKomponentenAuswahlElement"];

    variablerSetartikelAuswahlPopup.show({
      StuecklisteList: result.StuecklistenkomponentenAuswahlList,
      callback: async (auswahl) => {
        console.log(auswahl);
        this.variablerSetartikelKomponentenAuswahlList = auswahl;
        this.form.save();
      }
    });
  }

  private async showVariablerSetartikelPreisAendernAuswahl() {
    const variablerSetartikelPreisAendernPopup: VariablerSetartikelPreisAendern = this.form["r_variablerSetartikelPreisAendernElement"];

    variablerSetartikelPreisAendernPopup.show({
      callback: async (result: IVariablerSetartikelPreisAendernResult) => {
        if (result.RabattEntfernen) {
          const webEvent = new OnAuftragspositionVariablerSetartikelRabattEntfernenEvent({
            IdAuftragspositionSetartikel: this.form.models.data.$m_Auftragsposition.Id
          });

          await this._webEventService.execute(webEvent);
        } else if (result.ManuellerPreisEntfernen) {
          const webEvent = new OnAuftragspositionVariablerSetartikelManuellerPreisEntfernenEvent({
            IdAuftragspositionSetartikel: this.form.models.data.$m_Auftragsposition.Id
          });

          await this._webEventService.execute(webEvent);
        } else {
          const webEvent = new OnAuftragspositionVariablerSetartikelPreisAendernEvent({
            IdAuftragspositionSetartikel: this.form.models.data.$m_Auftragsposition.Id,
            Typ: result.KalkulationsFeldTyp,
            Wert: result.Wert
          });

          await this._webEventService.execute(webEvent);
        }

        this.form.models.reloadAll();
      }
    });
  }
}
