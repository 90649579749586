import * as fwx from "../../../../framework/forms/form-export";

@fwx.autoinject
export class FibuOssListForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport) {
        super(element, formBaseImport);
        this.id = "fibu-oss-list";
        this.title = "fibu-oss-list.fibu-oss-list_caption";
        this.addModel({
            "id": "$m_FibuOSS",
            "webApiAction": "ERP/Fibu/FibuOSS",
            "webApiExpand": {
                'Land': null
            },
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "filters": []
        });
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_fibuOSSTyp",
                "caption": "fibu-oss-list.r_fibuosstyp_caption",
                "bindTo": "OSSTyp",
                "minWidth": 100,
                "enumTypeName": "TIP.ERP.Business.Enumerations.OSSTyp"
            }, {
                "id": "r_fibuOSSFibuTyp",
                "caption": "fibu-oss-list.r_fibuossfibutyp_caption",
                "bindTo": "FibuTyp",
                "minWidth": 100,
                "enumTypeName": "TIP.ERP.Business.Enumerations.FibuTyp"
            }, {
                "id": "r_fibuOSSLand",
                "caption": "fibu-oss-list.r_fibuossland_caption",
                "bindTo": "Land.Bezeichnung",
                "minWidth": 100
            }],
            "optionsToolbar": {
                "optionsName": "r_fiboOSSGridToolbarOptions",
                "optionsNameFQ": "r_fiboOSSGridToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_FibuOSS"
            },
            "dataModel": "$m_FibuOSS",
            "editUrl": "ERP/Fibu/FibuOSS",
            "addShortscuts": true,
            "isMainList": true,
            "height": "100%",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_fiboOSSGrid",
            "options": {
                "optionsName": "r_fiboOSSGridOptions",
                "optionsNameFQ": "r_fiboOSSGridOptions"
            }
        });
        super.onConstructionFinished();
    }
}