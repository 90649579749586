import * as fwx from "../../../../framework/forms/form-export";

@fwx.autoinject
export class InventurpositionEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport) {
        super(element, formBaseImport);
        this.id = "inventurposition-edit";
        this.title = "inventurposition-edit.inventurposition-edit_caption";
        this.addModel({
            "id": "$m_Inventurposition",
            "webApiAction": "ERP/Lager/Inventurposition",
            "webApiExpand": {
                'Artikel': null
            },
            "optimisticLockingEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "allowNew": "models.data.$m_Inventur.CanSave",
            "allowSave": "models.data.$m_Inventur.CanSave",
            "allowDelete": "models.data.$m_Inventur.CanDelete",
            "filters": []
        });
        this.addModel({
            "id": "$m_Inventur",
            "webApiAction": "ERP/Lager/Inventur",
            "webApiExpand": {
                'Lager': null
            },
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "key": "models.data.$m_Inventurposition.IdInventur",
            "keyProperty": "Id",
            "filters": []
        });
        this.addVariable({
            "id": "$id"
        });
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "artikelEinheit",
            "filters": [],
            "caption": "inventurposition-edit.r_einheit_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Inventurposition",
                "bindTo": "Artikel.IdLagereinheit",
                "bindToFQ": "models.data.$m_Inventurposition.Artikel.IdLagereinheit",
                "propertyPrefix": "Artikel"
            },
            "validationRules": [],
            "id": "r_einheit",
            "options": {
                "optionsName": "r_einheitOptions",
                "optionsNameFQ": "r_einheitOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "format": "n2",
            "caption": "inventurposition-edit.r_sollmenge_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Inventurposition",
                "bindTo": "Sollmenge",
                "bindToFQ": "models.data.$m_Inventurposition.Sollmenge"
            },
            "validationRules": [],
            "id": "r_sollMenge",
            "options": {
                "optionsName": "r_sollMengeOptions",
                "optionsNameFQ": "r_sollMengeOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "format": "n6",
            "caption": "inventurposition-edit.r_einstandspreis_caption",
            "binding": {
                "dataContext": "$m_Inventurposition",
                "bindTo": "EinstandspreisWert",
                "bindToFQ": "models.data.$m_Inventurposition.EinstandspreisWert"
            },
            "validationRules": [],
            "id": "r_einstandspreis",
            "options": {
                "optionsName": "r_einstandspreisOptions",
                "optionsNameFQ": "r_einstandspreisOptions"
            }
        });
        super.onConstructionFinished();
    }
}