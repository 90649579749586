import * as fwx from "../../../../framework/forms/form-export";
import {
    LagerkorrekturEditUtils
} from "./lagerkorrektur-edit-utils";
import {
    StatusService
} from "../../../services/status-service";

@fwx.autoinject
export class LagerkorrekturEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_LagerkorrekturEditUtils: LagerkorrekturEditUtils,
        private $f_StatusService: StatusService) {
        super(element, formBaseImport);
        this.id = "lagerkorrektur-edit";
        this.title = "lagerkorrektur-edit.lagerkorrektur-edit_caption";
        this.addModel({
            "id": "$m_Lagerkorrektur",
            "webApiAction": "ERP/Lager/Lagerkorrektur",
            "webApiExpand": {
                'Artikel': {
                    'expand': {
                        'Einheit': null
                    }

                }
            },
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "filters": []
        });
        this.addModel({
            "id": "$m_Artikel",
            "webApiAction": "ERP/Stammdaten/Artikel",
            "webApiColumns": ["Id", "IdEinheit"],
            "webApiExpand": {
                'Einheit': {
                    'columns': [
                        'Id',
                        'Bezeichnung'
                    ]
                }
            },
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "key": "models.data.$m_Lagerkorrektur.IdArtikel",
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_LagerVon",
            "webApiAction": "ERP/Stammdaten/Lager",
            "webApiExpand": {
                'Einheit': {
                    'columns': [
                        'Id',
                        'Bezeichnung'
                    ]
                }
            },
            "optimisticLockingEnabled": true,
            "key": "models.data.$m_Lagerkorrektur.IdLagerVon",
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_LagerNach",
            "webApiAction": "ERP/Stammdaten/Lager",
            "webApiExpand": {
                'Einheit': {
                    'columns': [
                        'Id',
                        'Bezeichnung'
                    ]
                }
            },
            "optimisticLockingEnabled": true,
            "key": "models.data.$m_Lagerkorrektur.IdLagerNach",
            "keyProperty": "Id",
            "filters": []
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_LagerkorrekturEditUtils.doVerbuchen",
                "bindToFQ": "functions.$f_LagerkorrekturEditUtils.doVerbuchen"
            }
        });
        this.addFunction("$f_LagerkorrekturEditUtils", $f_LagerkorrekturEditUtils, "functions.$f_LagerkorrekturEditUtils");
        this.addFunction("$f_StatusService", $f_StatusService, "functions.$f_StatusService");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "lagerkorrektur-edit.r_datum_caption",
            "binding": {
                "dataContext": "$m_Lagerkorrektur",
                "bindTo": "Datum",
                "bindToFQ": "models.data.$m_Lagerkorrektur.Datum"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_datum",
            "options": {
                "optionsName": "r_datumOptions",
                "optionsNameFQ": "r_datumOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "status",
            "filters": [],
            "caption": "lagerkorrektur-edit.r_status_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Lagerkorrektur",
                "bindTo": "IdStatus",
                "bindToFQ": "models.data.$m_Lagerkorrektur.IdStatus"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "onValueChanged": "functions.$f_StatusService.onValueChanged",
            "id": "r_status",
            "options": {
                "optionsName": "r_statusOptions",
                "optionsNameFQ": "r_statusOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "lagerbuchungsart",
            "filters": [],
            "caption": "lagerkorrektur-edit.r_lagerbuchungsart_caption",
            "binding": {
                "dataContext": "$m_Lagerkorrektur",
                "bindTo": "IdLagerbuchungsart",
                "bindToFQ": "models.data.$m_Lagerkorrektur.IdLagerbuchungsart"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_lagerbuchungsart",
            "options": {
                "optionsName": "r_lagerbuchungsartOptions",
                "optionsNameFQ": "r_lagerbuchungsartOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "lagerMitFirmaFiliale",
            "filters": [],
            "caption": "lagerkorrektur-edit.r_lagervon_caption",
            "binding": {
                "dataContext": "$m_Lagerkorrektur",
                "bindTo": "IdLagerVon",
                "bindToFQ": "models.data.$m_Lagerkorrektur.IdLagerVon"
            },
            "validationRules": [{
                "item": {
                    "type": "conditionalRequired",
                    "parameters": [{
                        "name": "condition",
                        "value": "!models.data.$m_Lagerkorrektur.IdLagerNach"
                    }]
                }
            }],
            "id": "r_lagerVon",
            "options": {
                "optionsName": "r_lagerVonOptions",
                "optionsNameFQ": "r_lagerVonOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "lagerMitFirmaFiliale",
            "filters": [],
            "caption": "lagerkorrektur-edit.r_lagernach_caption",
            "binding": {
                "dataContext": "$m_Lagerkorrektur",
                "bindTo": "IdLagerNach",
                "bindToFQ": "models.data.$m_Lagerkorrektur.IdLagerNach"
            },
            "validationRules": [{
                "item": {
                    "type": "conditionalRequired",
                    "parameters": [{
                        "name": "condition",
                        "value": "!models.data.$m_Lagerkorrektur.IdLagerVon"
                    }]
                }
            }],
            "id": "r_lagerNach",
            "options": {
                "optionsName": "r_lagerNachOptions",
                "optionsNameFQ": "r_lagerNachOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "lagerkorrektur-edit.r_lagereinheit_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Artikel",
                "bindTo": "Einheit.Bezeichnung",
                "bindToFQ": "models.data.$m_Artikel.Einheit.Bezeichnung",
                "propertyPrefix": "Einheit"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }, {
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 100
                    }]
                }
            }],
            "id": "r_lagereinheit",
            "options": {
                "optionsName": "r_lagereinheitOptions",
                "optionsNameFQ": "r_lagereinheitOptions"
            }
        });
        this.widgetCreator.addTextArea(this, {
            "height": "200px",
            "caption": "lagerkorrektur-edit.r_kommentar_caption",
            "binding": {
                "dataContext": "$m_Lagerkorrektur",
                "bindTo": "Kommentar",
                "bindToFQ": "models.data.$m_Lagerkorrektur.Kommentar"
            },
            "validationRules": [],
            "id": "r_kommentar",
            "options": {
                "optionsName": "r_kommentarOptions",
                "optionsNameFQ": "r_kommentarOptions"
            }
        });
        super.onConstructionFinished();
    }
}